import { Button, Modal } from "react-bootstrap";
import { CompactPicker } from "react-color";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";


const ColorPickerButton = () => {
    const dispatch = useDispatch();
    const trace_info = useSelector((state) => state.settings.trace_info);
    const setTrace_info = (trace_info) => {
        dispatch({ type: "UPDATE_TRACE_INFO", payload: trace_info });
    }

    const key_color_hash = {};
    Object.keys(trace_info).forEach((key) => {
        key_color_hash[trace_info[key].label] = trace_info[key].color;
    });

    const handleColorChange = (index, newColor) => {
        const updatedTraceInfo = { ...trace_info };
        const key = Object.keys(updatedTraceInfo)[index];
        updatedTraceInfo[key].color = newColor.hex;
        setTrace_info(updatedTraceInfo);
    }


    return (
        <ColorPickerButtonFunction key_color_hash={key_color_hash} onColorChange={handleColorChange} />
    );

}

export default ColorPickerButton;


export const ColorPickerButtonFunction = ({ key_color_hash, onColorChange }) => {
    const [showModal, setShowModal] = useState(false);
    const [activePickerIndex, setActivePickerIndex] = useState(null);
  
    const handleCloseModal = () => {
      setShowModal(false);
      setActivePickerIndex(null);
    };
  
    return (
      <>
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Color Picker
        </Button>
  
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Trace Color Picker</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="list-group">
              {Object.keys(key_color_hash).map((name, index) => (
                <div
                  key={index}
                  className="list-group-item d-flex align-items-center justify-content-between"
                >
                  <span>{name || `Trace ${index + 1}`}</span>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        backgroundColor: key_color_hash[name],
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setActivePickerIndex(
                          activePickerIndex === index ? null : index
                        )
                      }
                    />
                    {activePickerIndex === index && (
                      <div style={{ position: "absolute", zIndex: 10 }}>
                        <CompactPicker
                          color={key_color_hash[name]}
                          onChange={(newColor) => {
                            onColorChange(index, newColor)
                            setActivePickerIndex(null)
                          }

                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
