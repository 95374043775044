import { default_user_plot_settings, getAxisColors, getAxisDomain, getAxisNames, getAxisUnits, normalizeTime } from "./utils";

    


export const loadLayoutV2 = (
    sensor_data,
    trace_info,
    axis_info,
    user_plot_settings = default_user_plot_settings,
) => {
    let record_time_data = sensor_data.record_time.map((el) =>
        normalizeTime(el)
    );

    // console.log('loadLayout called. n_rows: ', record_time_data.length)

    // //check if we must hide the legend
    // let width = window.innerWidth;
    // let show_legend = true;
    // if (width < 600) {
    //     show_legend = false;
    // }
    // console.log(width)

    // pick last element of record_time_data
    let last_record_time = record_time_data[record_time_data.length - 1];
    let first_record_time = record_time_data[0];


    // // define yaxes for each sensor
    // let y_sensor_data = {};
    // // remove record_time from sensor_data and store in y_sensor_data
    // for (let key of Object.keys(sensor_data)) {
    //     if (key !== "record_time") {
    //         y_sensor_data[key] = sensor_data[key];
    //     }
    // }


    // CALCULATE YAXES
    let yaxes = {};
    const axis_names = Object.keys(axis_info);
    let n_axis = axis_names.length;
    for (let i = 0; i < n_axis; i++) {
        let axis_key = 'yaxis' + (i + 1);
        let sep = user_plot_settings.plot_separation;
        let axis_name = axis_names[i]; //axis_name == label
        let axis_color = axis_info[axis_name].color;
        let j = i+1;
        sep = sep/user_plot_settings.plot_height;
        yaxes[axis_key] = {
                        title:  {
                            text: axis_name,
                            standoff: 60,
                            font: {
                            //   family: 'Courier New, monospace',
                              family: 'Arial Narrow, sans-serif',
                              color: axis_color,
                            },
                        },
                        autorange: true,
                        showspikes: true,
                        spikemode: "across",
                        ticksuffix: ' ' + axis_info[axis_name].unit,
                        domain: getAxisDomain(axis_name,axis_info,sep),
                        // domain: [sep + (1 / n_axis) * (j-1), (1 / n_axis) * (j)]
                    };
        if (axis_name === "INSFLG0" && user_plot_settings.log_INSFLG0) {
            yaxes[axis_key].type = "log";
        }
        if (user_plot_settings.all_log_scale) {
            yaxes[axis_key].type = "log";
        }
    }
    // replace yaxis COMMENTS to format for bar chart
    // get the yaxes that has title="COMMENTS"

    if( !user_plot_settings.hide_comments ){
        let comment_yaxis_key = Object.keys(yaxes).find(key => yaxes[key].title.text === "COMMENTS");
        if (comment_yaxis_key !== undefined) {
            console.log('comment_yaxis_key: ', comment_yaxis_key)
            let comment_yaxis = yaxes[comment_yaxis_key];
            yaxes[comment_yaxis_key] = {
                title:  comment_yaxis.title,
                domain: comment_yaxis.domain,
                zeroline: false,
                showgrid: false,
                showticklabels: false,
                // visible: false,
                // gridwidth: 2,
                range: [-0.8, 0.8],
            }
        }
    }





    // END OF CALCULATE YAXES



    let custom_range_slider;
    if (user_plot_settings.y_axis_zoom_and_pan){
        custom_range_slider = false;
    } else{
        custom_range_slider = {
            range: [first_record_time, last_record_time],
            thickness: 0.09*700/user_plot_settings.plot_height,
        }
    }

    // left margin calculation
    let left_margin = 50 + 50 * user_plot_settings.plot_font_size / 12;

    var layout = {
        autosize: true,
        first_record_time: first_record_time,
        last_record_time: last_record_time,
        margin: {l:left_margin,r:60}, //was 50,40
        font: {
            size: user_plot_settings.plot_font_size,
        },
        // title: mytitle,
        //vertical line
        // hovermode: 'x',
        hovermode: "closest", //better for missing data
        hoverlabel: { namelength: -1,
                    align: "right",
                    },
        showlegend: true,
        legend: {
            orientation: "h",
            x: 0,
            y: -0.35*500/user_plot_settings.plot_height,
        },
        // end of vertical line
        height: user_plot_settings.plot_height,
        // legend: { traceorder: 'reversed' },
        xaxis: {
            // vertical line
            showspikes: true,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            spikedash: "solid",
            showline: true,
            showgrid: true,
            //end of vertical line
            // autorange: true,
            // fixedrange: true,
            range: [first_record_time, last_record_time],
            // NOTE: enabling rangeslider disables panning on the y axis
            rangeslider: custom_range_slider,
            // rangeslider: true,
            type: "date",
        },
    };

    layout = { ...layout, ...yaxes };

    return layout;
};