// sensor_data={data}
// show_settings={show_settings}
// // start_time={start_time}
// // end_time={end_time}
// start_time={start_timeline}
// end_time={end_timeline}
// comments_dict={comments_dict}
// onUpdateResolution={onUpdateResolution}
// onCreateComment={onCreateComment}
// onUpdateComments={onUpdateComments}
// onPlotMountedChange={onPlotMountedChange}
// user_plot_settings={user_plot_settings}
// mode="fixed_udc"
// base_unit={active_udc[0] === "INSFLG0" ? "mfc":"psi"}
// sensor_id={active_udc[0]}
// account={account}

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { getNewPlotlyColor, getUDCInfo } from "bkv-components/General/utils";
import SensorPlotV2 from "bkv-components/SensorPlot/SensorPlotV2";
import { useDispatch, useSelector } from "react-redux";

// trace_info_example
// const trace_info = {
//     BP1-CMLINE0: {
//         well_id: "BP_A3U_YARBROUGH_B_2H",
//         sensor_id: "CMLINE0",
//         label: "BP1-CMLINE0",
//         yaxis_name: "CMLINE0",
//         color: 'blue',
//         unit: 'psi'
//     },
// }

const getUDCFromKey = (key) => {
    const key_split = key.split("-");
    return key_split[1];
};

const getWellFromKey = (key) => {
    const key_split = key.split("-");
    return key_split[0];
};

const build_trace_info = (sensor_data_keys, trace_info) => {

    // get well_id vs color
    let well_id_vs_color = {};
    Object.keys(trace_info).forEach((key) => {
        if (trace_info[key]) {
            well_id_vs_color[trace_info[key].well_id] = trace_info[key].color;
        }
    });
    let existing_colors = Object.values(well_id_vs_color);

    // main loop
    let new_trace_info = {};
    sensor_data_keys.forEach((key) => {
        if (key === "record_time") {
            //skip record_time
            return;
        }
        if (trace_info[key]) {
            new_trace_info[key] = trace_info[key];
            return;
        }
        // const trace = sensor_data[key];
        const well_id = getWellFromKey(key);
        if (!well_id_vs_color[well_id]) {
            well_id_vs_color[well_id] = getNewPlotlyColor(existing_colors);
            existing_colors.push(well_id_vs_color[well_id]);
        }
        const sensor_id = getUDCFromKey(key);
        new_trace_info[key] = {
            well_id: well_id,
            sensor_id: sensor_id,
            label: key,
            yaxis_name: sensor_id,
            color: well_id_vs_color[well_id],
            unit: getUDCInfo(sensor_id).unit,
        };
    });
    return new_trace_info;
};

const equal_lists_of_strings = (list1, list2) => {
    if (list1.length !== list2.length) {
        return false;
    }
    for (let i in list1) {
        if (list1[i] !== list2[i]) {
            return false;
        }
    }
    return true;
};

const MultiWellPlotHelper = (props) => {
    const {
        sensor_data, //dictionary
        show_settings,
        start_time,
        end_time,
        comments_dict,
        onUpdateResolution,
        onCreateComment,
        onUpdateComments,
        onPlotMountedChange,
        user_plot_settings,
        account,
    } = props;

    
    // const [trace_info, setTrace_info] = React.useState(build_trace_info(sensor_data_keys));
    const dispatch = useDispatch();
    const trace_info = useSelector((state) => state.settings.trace_info);
    const setTrace_info = (trace_info) => {
        dispatch({ type: "UPDATE_TRACE_INFO", payload: trace_info });
    }

    const sensor_data_keys = Object.keys(sensor_data);
    const sensor_data_keys_wo_record_time = sensor_data_keys.filter((key) => key !== "record_time");
    
    useEffect(() => {
        // check if sensor_data_keys has changed
        if (equal_lists_of_strings(sensor_data_keys_wo_record_time, Object.keys(trace_info))) {
            return;
        } else {
            const new_trace_info = build_trace_info(sensor_data_keys_wo_record_time,trace_info);
            // set trace_info
            setTrace_info(new_trace_info);
        }

    }, [sensor_data_keys_wo_record_time, trace_info]);

    //this line makes sure that trace_info has the same keys as sensor_data
    if (!equal_lists_of_strings(sensor_data_keys_wo_record_time, Object.keys(trace_info))) {
        return null;
    }

    // sort trace_info by yaxis_name (reverse alphabetical)
    const trace_info_sorted = {};
    const sorted_keys = Object.keys(trace_info).sort((b, a) => {
        return trace_info[a].yaxis_name.localeCompare(trace_info[b].yaxis_name);
    });
    sorted_keys.forEach((key) => {
        trace_info_sorted[key] = trace_info[key];
    });

    console.log(start_time, end_time)

    return (
        <div>
            <SensorPlotV2
                sensor_data={sensor_data}
                trace_info={trace_info_sorted}
                show_settings={show_settings}
                start_time={start_time}
                end_time={end_time}
                comments_dict={comments_dict}
                onUpdateResolution={onUpdateResolution}
                onCreateComment={onCreateComment}
                onUpdateComments={onUpdateComments}
                onPlotMountedChange={onPlotMountedChange}
                user_plot_settings={user_plot_settings}
                account={account}
                application={'multi'}
            />
        </div>
    );
};

MultiWellPlotHelper.propTypes = {
    // sensor data must be a dictionary
    sensor_data: PropTypes.object.isRequired,
    show_settings: PropTypes.bool.isRequired,
    // start_time of type date
    start_time: PropTypes.objectOf(Date).isRequired,
    end_time: PropTypes.objectOf(Date).isRequired,
};

export default MultiWellPlotHelper;
