import React from "react";

export const getCachedP2ScadaIds = async () => {
    const cacheStorage = await caches.open("data");
    let requests = await cacheStorage.keys();
    let urls = requests.map((r) => r.url);

    let cached_p2_scada_ids = [];
    urls.forEach((url, i) => {
        let searched_string = "/sensors/data/";
        // from url = 'http://localhost:8000/sensors/data/BPM_UIS_ACOLA_SAM_D_4?max_rows=120000&' does not qualify
        // from url = 'http://localhost:8000/sensors/data/BPM_UIS_ACOLA_SAM_D_4' get well_id='BPM_UIS_ACOLA_SAM_D_4'
        if (url.includes(searched_string)) {
            // filter only urls that have no parameters (no '?')
            if (!url.includes("?")) {
                let well_id = url.split(searched_string)[1];
                cached_p2_scada_ids.push(well_id);
            }
        }
    });
    // make cached_scada_ids unique
    cached_p2_scada_ids = [...new Set(cached_p2_scada_ids)];
    return cached_p2_scada_ids;
};

// export const dataCacheIncludesP2ScadaId = async (p2_scada_id) => {
//     const cached_p2_scada_ids = await getCachedP2ScadaIds();
//     return cached_p2_scada_ids.includes(p2_scada_id);
// };

// function waitForTrue(callback, timeout) {
//     return new Promise((resolve, reject) => {
//         let interval = setInterval(() => {
//             if (callback()) {
//                 clearInterval(interval);
//                 resolve();
//             }
//         }, 100);
//         setTimeout(() => {
//             clearInterval(interval);
//             reject();
//         }, timeout);
//     });
// }

export const waitForCachedP2ScadaId = (p2_scada_id,timeout=10000) => {
    // returns cached_p2_scada_ids if successful, else returns false
    return new Promise((resolve, reject) => {
        let interval = setInterval(async () => {
            const cached_p2_scada_ids = await getCachedP2ScadaIds();
            // console.log('looking for p2_scada_id',p2_scada_id,'in cached_p2_scada_ids');
            if (cached_p2_scada_ids.includes(p2_scada_id)) {
                clearInterval(interval);
                resolve(cached_p2_scada_ids);
            }
        }, 200);
        setTimeout(() => {
            clearInterval(interval);
            reject();
        }, timeout);
    });
};
    


export const addTooltipToLegends = () => {
    // add tooltip to labels
      // NOTE: https://stackoverflow.com/questions/28111480/adding-tooltip-to-svg-rect-tag
      const traces = document.querySelectorAll("g.traces");
      for (let i = 0; i < traces.length; i++) {
          const trace = traces[i];
          let label = trace.querySelector("text.legendtext").innerHTML;
          const get_tooltips = {
              "INSFLG0": "gas rate",
              "CMLINE0": "line pressure",
              "PCASE0": "casing pressure",
              "PTUBE0": "tubing pressure",
          }
          let rect = trace.querySelector("rect");
          //check if rect has child element title
          const tooltip = get_tooltips[label];
          if (!rect.querySelector("title") && tooltip) {
              rect.innerHTML = `  <title> ${tooltip} </title>`;
          }
      }
  }


export const RefreshContext = React.createContext();


let color_list = [
    '#1f77b4', // # muted blue
    '#ff7f0e', // # safety orange
    '#2ca02c', // # cooked asparagus green
    '#d62728', // # brick red
    '#9467bd', // # muted purple
    '#8c564b', // # chestnut brown
    '#e377c2', // # raspberry yogurt pink
    '#7f7f7f', // # middle gray
    '#bcbd22', // # curry yellow-green
    '#17becf'  // # blue-teal
]

export const getPlotlyColor = (i) => {

    // from https://community.plotly.com/t/plotly-colours-list/11730/6
    // let color_list = colors.split(",").map((color) => color.trim());

    return color_list[i % color_list.length];
};

export const getNewPlotlyColor = (existing_colors) => {
    let new_color = color_list.find((color) => !existing_colors.includes(color));
    return new_color;
};

export const getUDCInfo = (udc) => {
    // INSFLG0: '#d62728', // brick red
    //     CMLINE0: '#ff7f0e', // orange
    //     PTUBE0: '#9467bd', // purple
    //     PCASE0: '#17becf', // cyan

    // export const udc_names = {
    //     gas_production: "GAS (mcf/d)",
    //     oil_production: "OIL (bbl/d)",
    //     water_production: "WATER (bbl/d)",
    //     casing_pressure: "CASING (psi)",
    //     tubing_pressure: "TUBING (psi)",
    //     line_pressure: "LINE (psi)",
    //     gas_target: "GAS TARGET (mcf/d)",
    //     gas_budget: "GAS BUDGET (mcf/d)",
    //     gas_forecast: "GAS FORECAST (mcf/d)",
    // };

    const udc_global_info = {
        //sensor data
        "INSFLG0": {
            "label": "gas rate",
            "color": "#d62728",
            "unit": "mfc",
        },
        "CMLINE0": {
            "label": "line pressure",
            "color": "#ff7f0e",
            "unit": "psi",
        },
        "PCASE0": {
            "label": "casing pressure",
            "color": "#9467bd",
            "unit": "psi",
        },
        "PTUBE0": {
            "label": "tubing pressure",
            "color": "#17becf",
            "unit": "psi",
        },
        "LOADFACTOR": {
            "label": "load factor",
            "color": "#2ca02c",
            "unit": "",
        },
        //daily data
        "gas_production": {
            "label": "GAS (mcf/d)",
            "unit": "mcf/d",
        },
        "oil_production": {
            "label": "OIL (bbl/d)",
            "unit": "bbl/d",
        },
        "water_production": {
            "label": "WATER (bbl/d)",
            "unit": "bbl/d",
        },
        "casing_pressure": {
            "label": "CASING (psi)",
            "unit": "psi",
        },
        "tubing_pressure": {
            "label": "TUBING (psi)",
            "unit": "psi",
        },
        "line_pressure": {
            "label": "LINE (psi)",
            "unit": "psi",
        },
        "gas_target": {
            "label": "GAS TARGET (mcf/d)",
            "unit": "mcf/d",
        },
        "gas_budget": {
            "label": "GAS BUDGET (mcf/d)",
            "unit": "mcf/d",
        },
        "gas_forecast": {
            "label": "GAS FORECAST (mcf/d)",
            "unit": "mcf/d",
        },
        "gasbuyback_whp": {
            "label": "GAS BUYBACK WHP (mcf/d)",
            "unit": "mcf/d",
        },
    };

    
    if (!udc_global_info[udc]) {
        console.error("udc not found in udc_global_info", udc);
        return {
            "label": udc,
            "unit": "",
        }
    }
    return udc_global_info[udc];
};
