/*
* In this version of SensorPlot, sensor_data is a dictionary that includes more variables in the key:
* Example: 
* sensor_data = {
*   "trace_xxx": [1,2,3,4],
*   "record_time": [1,2,3,4]
* }
* trace_info = {
*    trace_001: { 
*        well_id: 'Well2',
*        sensor_id: 'UDC001',
*        yaxis_name: 'UDC001',
*    }
*    trace_002: {
*        well_id: 'Well3',
*        sensor_id: 'UDC002',
*        yaxis_name: 'UDC002',
*    }
* }
*/

import React, { useEffect, useState } from 'react'
import { getAxisInfoFromTraceInfo, getTraceInfoFromAxisInfoAndTraceInfo, normalizeTime} from "./Components/utils";

import { Provider, useDispatch } from 'react-redux'

import rootReducer from './store/reducers/rootReducer'
import { applyMiddleware, createStore } from 'redux'

import thunk from 'redux-thunk'
import Plotly from "plotly.js-gl2d-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
import { onUnhoverAction, setCurrentAnnotationXAction, setCurrentPointAction, setShowMenuAction, setShowModalAction, setXPosAction, setYPosAction } from './store/actions/commentActions';
import { addTooltipToLegends } from '../General/utils';
// import CommentMenus from './Comments/CommentMenus';
import PlotButtonsAndModals from './Components/PlotButtonsAndModals';
import { loadPlotDataV2 } from './Components/utils_loadPlotDataV2';
import { loadLayoutV2 } from './Components/utils_loadPlotLayoutV2';
import { createAnnotationsFromComments_v2, createDataFromCommentsDictExternal } from './Comments/comment_utils_v2';
import PropTypes from 'prop-types';
import CommentMenus from './Comments/CommentMenus_v2';
const Plot = createPlotlyComponent(Plotly);

const store = createStore(rootReducer, applyMiddleware(thunk))

function SensorPlotV2(props) {
  return (
    <Provider store={store}>
      <SensorPlotBase {...props} />
    </Provider>
  )
}

export default SensorPlotV2;

//define proptype of trace_info
const trace_info_prop_type = PropTypes.shape({
    well_id: PropTypes.string.isRequired,
    sensor_id: PropTypes.string.isRequired,
    yaxis_name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
})

const sensor_data_prop_type = PropTypes.shape({
    record_time: PropTypes.array.isRequired,
    // any other key is allowed with value of array
}).isRequired

const comment_element_prop_type = PropTypes.shape({
    id: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    p2_scada_id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    user_first_name: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired,
    created_at: PropTypes.number.isRequired,
    edited_and_replaced: PropTypes.bool.isRequired,
    application: PropTypes.string.isRequired,
})

const axis_info_prop_type = PropTypes.shape({
    unit: PropTypes.string,
    domain_height: PropTypes.number.isRequired,
    color: PropTypes.string,
})

SensorPlotBase.propTypes = {
    sensor_data: sensor_data_prop_type, 
    trace_info: PropTypes.objectOf(trace_info_prop_type).isRequired,
    axis_info: PropTypes.objectOf(axis_info_prop_type),//optional
    comments_dict: PropTypes.objectOf(comment_element_prop_type).isRequired,
    user_plot_settings: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    start_time: PropTypes.objectOf(Date).isRequired,
    end_time: PropTypes.objectOf(Date).isRequired,
    show_settings: PropTypes.bool,
    onUpdateResolution: PropTypes.func,
    onCreateComment: PropTypes.func,
    onUpdateComments: PropTypes.func,
    onPlotMountedChange: PropTypes.func,
    application: PropTypes.string,

}




function SensorPlotBase(props) {
    /*
    shape of sensor data:
        sensor_data = {
            record_time: [],
            label_1: [],
            label_2: [],
            label_i: [],
        }
    */
    const {
        sensor_data,
        trace_info,
        axis_info, //optional (can be defined for color, sorting and domain definition)
        comments_dict,
        show_settings,
        start_time,
        end_time,
        onUpdateResolution,
        onCreateComment,
        onUpdateComments,
        onPlotMountedChange,
        user_plot_settings,
        account,
        comments_dict_external = {},
        application = 'surveillance_dashboard',
    } = props;

    // get axis_info if it is not provided
    let axis_info_helper;
    let trace_info_helper = trace_info;
    if (axis_info === undefined) {
        axis_info_helper = getAxisInfoFromTraceInfo(trace_info);
    } else {
        trace_info_helper = getTraceInfoFromAxisInfoAndTraceInfo(axis_info, trace_info);
        axis_info_helper = axis_info;
    }

    





    const sensor_data_is_loaded = Object.keys(sensor_data).length > 0;
    if (sensor_data_is_loaded) {
        const sensor_data_length = sensor_data.record_time.length;
        var sensor_data_first = normalizeTime(sensor_data.record_time[0]);
        var sensor_data_last = normalizeTime(
            sensor_data.record_time[sensor_data_length - 1]
        );
        var sensor_data_hash =
            sensor_data_length + sensor_data_first + "x" + sensor_data_last;
    }

    const dispatch = useDispatch();
    const [plot_layout, setPlotLayout] = useState(null);
    const [plot_data, setPlotData] = useState([]);
    const [plot_mounted, setPlotMounted] = useState(false);

    const handlePlotMountedChange = (plot_mounted) => {
        onPlotMountedChange(plot_mounted);
        setPlotMounted(plot_mounted);
    };

    // comments stuff
    const showCommentModal = (e) => {
        //= > dispatch(showCommentModalAction(e));
        console.log(e)
        console.log("showCommentModal");
        const current_point = { ...e.annotation.annotation_point };
        const x_pos = current_point.x;
        dispatch(setCurrentPointAction(current_point));
        dispatch(setCurrentAnnotationXAction(x_pos + e.annotation.label));
        dispatch(setShowModalAction(true));
    };
    const onUnhover = (e) => dispatch(onUnhoverAction(e));
    const showAddCommentMenu = (
        el // dispatch(showAddCommentMenuAction(e));
    ) => {
        console.log(el);
        dispatch(setXPosAction(`${el.event.pageX}px`));
        dispatch(setYPosAction(`${el.event.pageY}px`));

        // console.log(el.points[0].data.id);
        let trace_id = el.points[0].data.id;
        if (el.event.button === 0) {
            let point_p2_scada_id = trace_info_helper[trace_id].well_id;
            let point_sensor_id = trace_info_helper[trace_id].sensor_id;
            let yaxis_name = trace_info_helper[trace_id].yaxis_name;
            // el.event.preventDefault();
            // toggle showMenu
            let x_pos = new Date(el.points[0].x);
            x_pos = x_pos.getTime();
            const current_point = {
                x: x_pos,
                //THIS IS A FIX TO PREVENT PLOTLY CRASH ON LOG SCALE
                y: el.points[0].y === 0 ? 0.001 : el.points[0].y,
                // name: el.points[0].data.name,
                p2_scada_id: point_p2_scada_id,
                sensor_id: point_sensor_id,
                yaxis: el.points[0].data.yaxis,
            };
            dispatch(setCurrentPointAction(current_point));
            const current_annotation_x = current_point.x + point_sensor_id; //IMPROVE: can be replaced by + yaxis_name
            dispatch(setCurrentAnnotationXAction(current_annotation_x));
            dispatch(setShowMenuAction(true));
        }
    };
    // const comments_dict = useSelector((state) => state.raw_data.comments_dict);
    const plot_data_range = [sensor_data_first, sensor_data_last];

    let ann_dict = createAnnotationsFromComments_v2(
        comments_dict,
        plot_data_range,
        user_plot_settings,
        trace_info_helper,
    );

    let ann_list = Object.values(ann_dict).filter(
        (ann) => ann.hidden === false
    );

    // TODO DELETE THIS
    // // // EXTERNAL COMMENTS AND ANNOTATIONS

    // // let external_ann_dict = createAnnotationFromExternalComments(
    // //     comments_dict_external,
    // //     plot_data_range,
    // //     user_plot_settings
    // // );

    // // let external_ann_list = Object.values(external_ann_dict).filter(
    // //     (ann) => ann.hidden === false
    // // );

    // // // add shapes to plot layout
    // // let shapes = createShapesFromExternalAnnotations(external_ann_list);



    // // //merge external and internal annotations
    // // ann_list = ann_list.concat(external_ann_list);





    const plot_layout_w_annotations = {
        ...plot_layout,
        annotations: user_plot_settings.hide_comments ? [] : ann_list,
    };

    const [revision, setRevision] = useState(0);

    const onRelayout = (event_data) => {
        let data_start = new Date(event_data["xaxis.range[0]"]).getTime();
        let data_end = new Date(event_data["xaxis.range[1]"]).getTime();

        let range_boundaries = [start_time, end_time];
        let new_range = [...range_boundaries];
        if (event_data["xaxis.range[0]"]) {
            new_range = [data_start, data_end];
        }

        let new_layout = {
            ...plot_layout,
            annotations: user_plot_settings.hide_comments ? [] : ann_list,
        };

        let change_flag = false;
        if (new_range[0] < range_boundaries[0]) {
            console.log("new range is less than range boundaries");
            new_layout.xaxis.range[0] = range_boundaries[0];
            change_flag = true;
        }
        if (new_range[1] > range_boundaries[1]) {
            console.log("new range is greater than range boundaries");
            new_layout.xaxis.range[1] = range_boundaries[1];
            change_flag = true;
        }
        if (change_flag) {
            setPlotLayout(new_layout);
            setRevision(revision + 1);
            console.log("just relayout with changes");
        } else {
            console.log("just relayout without changes");
        }

        // const setPlotMounted = (e) => dispatch(setPlotMountedAction(e));
        // setPlotMounted(true);
        handlePlotMountedChange(true);
        // console.timeEnd('Plotly')
    };

    useEffect(() => {
        if (sensor_data_is_loaded) {


            //create trace from comments_dict_external
            let external_comments_data = undefined;
            let external_comments_exist = Object.keys(comments_dict_external).length > 0;
            if (!user_plot_settings.hide_comments && external_comments_exist) {
                external_comments_data = createDataFromCommentsDictExternal(comments_dict_external,sensor_data.record_time);
            }
            

            let sensor_data_helper = sensor_data;

            if (external_comments_data !== undefined) {
                // add external comments to sensor_data
                sensor_data_helper = {
                    ...sensor_data_helper,
                    ...external_comments_data.sensor_data,
                };
                // add external comments to trace_info
                // eslint-disable-next-line react-hooks/exhaustive-deps
                trace_info_helper = {
                    ...trace_info_helper,
                    ...external_comments_data.trace_info,
                };

                let n_axis = Object.keys(axis_info_helper).length;
                // eslint-disable-next-line react-hooks/exhaustive-deps
                axis_info_helper = {
                    ...axis_info_helper,
                    ...external_comments_data.axis_info,
                };

                // fix axis_info domain_height

                let additional_domain_height = Object.values(external_comments_data.axis_info)[0].domain_height;
                for (let i = 0; i < n_axis; i++) {
                    let key = Object.keys(axis_info_helper)[i];
                    axis_info_helper[key].domain_height -= additional_domain_height / n_axis;
                }

            }




            // let base_unit = "psi";
            // if (mode === "fixed_udc" && sensor_id === "INSFLG0") {
            //     base_unit = "mfc";
            // } else if (mode === "daily") {
            //     base_unit = "";
            // }
            // if (!base_unit){
            //     base_unit = "psi";
            // }
            let plot_layout = loadLayoutV2(
                sensor_data_helper,
                trace_info_helper,
                axis_info_helper,
                user_plot_settings,
            );
            // console.timeEnd('loadPlotLayout')
            setPlotLayout(plot_layout);
            // console.time('loadPlotData')
            let plot_data = loadPlotDataV2(
                sensor_data_helper,
                trace_info_helper,
                user_plot_settings,
            );
            // console.timeEnd('loadPlotData')
            setPlotData(plot_data);
            console.log("plot_layout and plot_data reloaded");
            // console.time('Plotly')
        } else {
            setPlotLayout(null);
            setPlotData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sensor_data_hash,
        sensor_data,
        sensor_data_is_loaded,
        user_plot_settings.merged_pressure_plots,
        user_plot_settings.log_INSFLG0,
        user_plot_settings.plot_height,
        user_plot_settings.plot_separation,
        user_plot_settings.y_axis_zoom_and_pan,
        user_plot_settings.connectgaps,
        user_plot_settings.smoothing,
        user_plot_settings.hide_comments,
        user_plot_settings.plot_font_size,
        trace_info,
    ]);

    // on unmount plot_mounted = false
    useEffect(() => {
        return () => {
            // dispatch(setPlotMountedAction(false));
            handlePlotMountedChange(false);
        };
    }, [dispatch]);

    const onUpdate = (figure) => {
        // used only for debugging
        // console.log("onUpdate");
        // console.log(figure.layout.xaxis.range);
        // console.log(figure.layout.xaxis.rangeslider.range);
    };

    const onAfterPlot = (figure) => {
        // const setPlotMounted = (e) => dispatch(setPlotMountedAction(e));
        // setPlotMounted(true);
        handlePlotMountedChange(true);

        addTooltipToLegends();
    };

    // const onAutosize = (figure) => {
    //     // const setPlotMounted = (e) => dispatch(setPlotMountedAction(e));
    //     // // setPlotMounted(false);
    //     // setPlotMounted(true);
    //     // console.log("onAutosize", figure);
    // };

    var colors = ["green", "red", "blue"];

    var icon1 = {
        width: 512,
        height: 512,
        path: "M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336 304v64h32H464h32V192 64 32H432V64v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4l60.4 21.3zM80 396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7l-60.4-21.3c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176h32V288H176 48 16v32V448v32H80V448 396.9z",
    };

    var refresh_icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336 304v64h32H464h32V192 64 32H432V64v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4l60.4 21.3zM80 396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7l-60.4-21.3c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176h32V288H176 48 16v32V448v32H80V448 396.9z" />
        </svg>
    );

    const onRefresh = () => {
        if (onUpdateResolution){
            //convert start_time to timestamp
            console.log("refreshing");
            let startTime = new Date(start_time);
            let endTime = new Date(end_time);
            onUpdateResolution(startTime, endTime);
        }
    };

    return (
        <div>
            {sensor_data_is_loaded && (
                <div>
                    <CommentMenus
                        ann_dict={ann_dict}
                        onCreateComment={onCreateComment}
                        onUpdateComments={onUpdateComments}
                        // mode={mode}
                        // sensor_id={comment_menu_sensor_id}
                        // p2_scada_id={comment_menu_p2_scada_id}
                        // yaxis_name={comment_menu_yaxis_name}
                        comments_dict={comments_dict}
                        account={account}
                        application={application}
                    />
                    <Plot
                        data={plot_data}
                        // layout={plot_layout}
                        layout={plot_layout_w_annotations}
                        style={{ width: "100%", height: "100%" }}
                        useResizeHandler={true}
                        config={{
                            modeBarButtonsToRemove: ["lasso2d", "select2d"],
                            displaylogo: false,
                            scrollZoom: true,
                            displayModeBar: true, //always show modebar (delete to show only on hover)

                            //NOT WORKING FIXME
                            // modeBarButtonsToAdd: [
                            //     {
                            //       name: 'Refresh',
                            //       icon: icon1,
                            //       click: ()=>onRefresh()
                            //     },
                            //     ]
                        }}
                        // comments stuff
                        onClickAnnotation={showCommentModal}
                        onUnhover={onUnhover}
                        onClick={showAddCommentMenu}
                        // update button stuff
                        onRelayout={onRelayout}
                        onUpdate={onUpdate}
                        onAfterPlot={onAfterPlot}
                        // onAutoSize={onAutosize}
                    />
                    <PlotButtonsAndModals
                        show_settings={show_settings}
                        plot_layout={plot_layout}
                        onUpdateResolution={onUpdateResolution}
                        sensor_data={sensor_data}
                        // mode={mode}
                        plot_mounted={plot_mounted}
                        comments_dict={comments_dict}
                    />
                </div>
            )}
        </div>
    );
}




