import settingsReducer, { settingsInitState } from "bkv-components/store_lib/reducers/settingsReducer"

const initState = {
  ...JSON.parse(JSON.stringify(settingsInitState)),
  user_plot_settings: {
    ...JSON.parse(JSON.stringify(settingsInitState.user_plot_settings)),
    connectgaps: true,
    log_INSFLG0: false,
    merged_pressure_plots: false,
    hide_comments: false,
    // plot_height: 700,
    // plot_separation: 0,
  }
}

const settingsReducerSingleWell = (state = { ...initState }, action) => {
  return settingsReducer(state, action, initState)
}

export default settingsReducerSingleWell
