import Header from "bkv-components/Layout/Header";
import Layout from "bkv-components/Layout/Layout";
import { getApiDefaults } from "bkv-components/store_lib/actions/dataActions";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactTabulator } from "react-tabulator";
import AdminLogQuery from "./AdminLogQuery";
import { Tab, Tabs } from "react-bootstrap";

const MainBody = styled.div`
    /* min-height 100vh - height 105.062px */

    min-height: calc(100vh - 105.062px);
`;

//center column headers
const common_cols = {
    hozAlign: "center",
    maxWidth: 200,
    minWidth: 100,
    headerHozAlign: "center",
    headerSort: true,
    //2 decimal places
};

const user_summary_columns = [
    {
        title: "User",
        field: "unique_name",
        minWidth: 100,
        maxWidth: 200,
        tooltip: true,
    },
    { title: "last_30_days", field: "last_30_days", ...common_cols },
    { title: "last_7_days", field: "last_7_days", ...common_cols },
    { title: "last_3_days", field: "last_3_days", ...common_cols },
    { title: "last_1_days", field: "last_1_days", ...common_cols },
];

const common_app_summary_cols = [
    {
        title: "Daily Users",
        field: "total_unique_users",
        ...common_cols,
        tooltip: "unique daily total users",
    },
    { title: "Admin", field: "admin_count", ...common_cols },
    { title: "Single Well", field: "single_count", ...common_cols },
    { title: "Multi Well", field: "multi_count", ...common_cols },
    { title: "Daily and Engineering", field: "daily_count", ...common_cols },
];

// day  daily_count  admin_count  single_count  multi_count
const app_summary_columns = [
    { title: "Day", field: "day", minWidth: 100, maxWidth: 200 },
    ...common_app_summary_cols,
];

const app_summary_columns_monthly = [
    { title: "Month", field: "month", minWidth: 100, maxWidth: 200 },
    ...common_app_summary_cols,
];

const SummaryTable = ({ title, data, columns }) => (
    <div>
        <h2>{title}</h2>
        <hr />
        <ReactTabulator
            data={data}
            columns={columns}
            options={{
                // layout:"fitData",
                layout: "fitColumns",
                // autoResize:true,
                // resizableColumns:true,
                responsiveLayout: true,
                pagination: "local",
                paginationSize: 10,
            }}
        />
    </div>
);

function AdminDashboardApp() {
    const [log_summary_data, setLogSummary] = React.useState(null);
    const [error, setError] = React.useState(false);

    const [tab_key, setKey] = React.useState("summary");

    // make api call on mount using useEffect
    useEffect(() => {
        // make api call to
        const get_log_summary = async () => {
            const { api_endpoint, headers } = await getApiDefaults();

            try {
                const response = await fetch(
                    `${api_endpoint}/admin/log_summary`,
                    {
                        headers: headers,
                    }
                );
                const data = await response.json();
                setError(false);
                setLogSummary(data);
            } catch (error) {
                setError(error.message);
            }
        };

        get_log_summary();

        // set data to state
    }, []);

    console.log(log_summary_data);

    if (error) {
        return <div>Error:{error}</div>;
    }

    if (log_summary_data === null) {
        return <div>Loading...</div>;
    }

    const download_log_summary_data = () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(log_summary_data)], {
            type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = "log_summary_data.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const { users_summary, app_summary_daily, app_summary_monthly_avg } =
        log_summary_data;

    return (
        <Layout>
            <Header title="Admin Dashboard" />
                <Tabs
                    id="controlled-tab-example"
                    activeKey={tab_key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="summary" title="Summary">
                        {log_summary_data && (
                            <MainBody className="maincontainer p-5">
                                <SummaryTable
                                    title="Summary - API calls"
                                    data={users_summary}
                                    columns={user_summary_columns}
                                />
                                <SummaryTable
                                    title="App Summary - Users Daily"
                                    data={app_summary_daily}
                                    columns={app_summary_columns}
                                />
                                <SummaryTable
                                    title="App Summary - Users Monthly Daily Average"
                                    data={app_summary_monthly_avg}
                                    columns={app_summary_columns_monthly}
                                />
                                <button onClick={download_log_summary_data}>Download Log Summary Data</button>
                            </MainBody>
                        )}

                        {/* add button to download log_summary_data */}


                    </Tab>
                    <Tab eventKey="log_query" title="Log Queries">
                        <AdminLogQuery />
                    </Tab>
                </Tabs>
        </Layout>
    );
}

export default AdminDashboardApp;
